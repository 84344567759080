<template>
  <div>
    <HeaderPanel
      title="OTP LOG"
      :filter="filter"
      placeholder="Search Telephone, Email, Ref. Code"
      @search="getList"
      :hasDropdown="false"
      :hasFilter="false"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(createTime)="{ item }">
              <div>{{ item.createTime | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ item.createTime | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-slot:cell(telephone)="data">
              <div>{{ convertTelePhone(data.item.telephone) }}</div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";

export default {
  name: "age_Group",
  components: {
    HeaderPanel,
  },
  data() {
    return {
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "telephone",
          label: "Phone Number / Email",
        },
        {
          key: "reference",
          label: "Ref. Code",
          class: "text-center",
        },
        {
          key: "otp",
          label: "OTP",
        },
        { key: "createTime", label: "Date/Time" },
      ],
      items: [],
    };
  },
  created() {
    this.getList();
  },

  methods: {
    async getList() {
      this.isBusy = true;
      const response = await this.axios.post(`/otp/getlist`, this.filter);

      this.rows = response.data.detail.count;
      this.items = response.data.detail.detail;
      this.isBusy = false;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
  },
};
</script>
